<template>
  <header class="text-gray-600 body-font">
    <div class="mx-auto flex flex-wrap flex-row items-center lg:px-12 xl:px-24">
      <a href="/" class="text-gray-900 text-xl tracking-[0.2em] p-4"> TTD </a>
      <nav
        class="hidden md:flex md:mr-auto md:pl-4 md:border-l md:border-gray-400 flex-wrap items-center text-base justify-center"
      >
        <nuxt-link v-for="link in links" :key="link.name" :to="link.href" class="mr-5 hover:text-gray-900">
          {{ link.name }}
        </nuxt-link>
      </nav>
      <button
        @click="handleClick"
        class="hidden md:block btn btn-outline btn-sm border border-gray-400 text-grey-400 mr-4"
      >
        Get A Quote
      </button>
      <div
        v-if="!showMobileMenu"
        class="md:hidden ml-auto flex p-4 text-2xl cursor-pointer items-center justify-center"
        @click="showMobileMenu = true"
      >
        <Icon class="" name="iconamoon:menu-burger-horizontal-duotone" />
      </div>
      <div
        v-else
        class="md:hidden ml-auto flex p-4 text-2xl cursor-pointer items-center justify-center"
        @click="showMobileMenu = false"
      >
        <Icon class="" name="ph:x" />
      </div>
    </div>

    <!-- Mobile Menu -->
    <div v-if="showMobileMenu" ref="mobileMenu" class="absolute z-10 bg-white w-full shadow">
      <div>
        <nuxt-link
          v-for="link in links"
          :key="link.name"
          :to="link.href"
          class="block p-4 hover:bg-gray-100"
          @click="showMobileMenu = false"
        >
          {{ link.name }}
        </nuxt-link>
      </div>
    </div>
  </header>
</template>

<script setup lang="ts">
import { onClickOutside } from "@vueuse/core";
const showMobileMenu = ref(false);
const mobileMenu = ref(null);

const handleClick = () => {
  window.location.href = "https://www.youtube.com/watch?v=dQw4w9WgXcQ";
};

onClickOutside(mobileMenu, () => {
  showMobileMenu.value = false;
});

const links = [
  {
    name: "Home",
    href: "/",
  },
  {
    name: "Services",
    href: "/services",
  },
  {
    name: "Technologies",
    href: "/technologies",
  },
  {
    name: "Industry",
    href: "/industry",
  },
  {
    name: "Company",
    href: "/company",
  },
];
</script>
